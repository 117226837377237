<!-- 商品管理 -->
<template>
    <div class="zt-page-content">
        <div style="min-width:960px;">
            <div class="shPerson">
                <div class="shPerson-header flex">
                    <span class="flex-1">收货人信息</span>
                    <span @click="editor_address_show()" class="addSite flex">新增收货地址</span>
                </div>
                <div class="shPerson-content">
                    <div v-for="(item,index) in addressList" :key="index" class="shPerson-content-item flex">
                        <div class="shPerson-item-left flex-1">
                            <span class="shPerson-radio">
                                <input :disabled="shPersonEditor==true" type="radio" @click="selectAddress(index)" :checked="index == addressIdx" name="radio" :id="index" class="regular-radio pull-right"/>
                                <label :for="index"></label>
                            </span>
                            <span>{{item.name}}</span>
                            <span>{{item.city}}</span><span>{{item.area}}</span>
                            <span>{{item.address}}</span>
                        </div>
                        <div class="shPerson-item-right flex">
                            <span v-if="shPersonEditor==false">
                                <!-- <template v-if="item.state == 0">
                                    <el-button type="text" @click="defaultAddress()">设为默认地址</el-button>
                                </template> -->
                                <template v-if="index==0">
                                    <el-button type="text" disabled>默认地址</el-button>
                                </template>
                            </span>
                            <span v-if="shPersonEditor==false"><el-button type="text" @click="editorAddress(item.id)">编辑</el-button></span>
                            <span v-if="shPersonEditor==false"><el-button type="text" @click="popRemove(item.id)">删除</el-button></span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="shPersonEditor" class="shPersonEditor">
                <div class="shPerson-header flex">
                    <span class="flex-1">收货人信息</span>
                </div>
                <div class="shPersonEditor-content">
                    <el-form ref="form" :model="addressInfo" label-width="80px">
                        <el-form-item label="收货人">
                            <el-input style="width: 240px;" v-model="addressInfo.name" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码">
                            <el-input style="width: 240px;" v-model="addressInfo.phone" maxlength="11" placeholder="手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="所在地区">
                            <!-- <el-cascader :options="options" :value="region" @change="regionChange"
                                :separator="' '">
                            </el-cascader> -->
                            <RegionSelect v-model="addressInfo"></RegionSelect>
                        </el-form-item>
                        <!-- <el-form-item label="即时配送">
                            <el-switch style="color: #CC995A;" v-model="address.delivery" active-color="#CC995A"
                                    active-text="是"
                                    inactive-text="否"></el-switch>
                        </el-form-item> -->
                        <el-form-item label="详细地址">
                            <el-input type="textarea" style="width: 400px;min-height: 80px;" v-model="addressInfo.address"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="editor_address_hide()">取消</el-button>
                            <el-button type="primary" @click="subAddress()"> 新增/修改</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditSite :form="ruleForm" @refreshItems="refreshItems" @hide="popVisible=false"></EditSite>
        </el-dialog>
        
    </div>
</template>

<script>
    import {  } from '@/service/company';
    import { IMG_URL_PRE } from '@/config'
    import { listAddress,saveAddress,delAddress,getAddressById } from '@/service/sale';
    import RegionSelect from '@/components/region/Index.vue'
    import EditSite from './EditSite.vue';
    export default {
        components: {
            EditSite,
        },
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                popVisible: false,
                popTitle: '',
                addressList:[],
                addressInfo:{
                    name:null,
                    phone:null,
                    province:null,
                    city:null,
                    address:null,
                    isDefault:false,
                },
                addressIdx:0,
                shPersonEditor:false,
            }
        },
        created() {
            this.address_list();
        },
        methods: {
            goto(path){
                this.$router.push(path);
            },
            address_list() {
                listAddress().then(u=>{
                    this.addressList=u.rows;
                    this.total=u.count;
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            selectAddress(index) {
                this.addressIdx = index;
                this.addressInfo = this.addressList[index];
            },
            // 修改地址
            editorAddress(id) {
                this.shPersonEditor = true;
                getAddressById(id).then(u=>{
                    this.addressInfo = {
                        id:u.id,
                        name:u.name,
                        phone:u.phone,
                        province:u.province,
                        city:u.city,
                        address:u.address,
                        isDefault:u.isDefault,
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            // 设置默认地址
            defaultAddress() {},
            // 删除
            popRemove(id) {
                var idArr = [];
                idArr.push(id);
                this.$confirm('是否删除该地址？', '提示', {confirmButtonText: '确定',
                    cancelButtonText: '取消',type: 'warning',
                }).then(() => {
                    delAddress(idArr).then(u=>{
                        this.address_list();
                        this.$message({ type: 'success', message: '删除成功!'});
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                })
            },
            editor_address_show() {
                this.addressInfo = {
                        name:null,
                        phone:null,
                        province:null,
                        city:null,
                        address:null,
                        isDefault:false,
                    }
                this.shPersonEditor = true;
            },
            // 提交地址
            subAddress() {
                var text = "";
                if (this.addressInfo.id) {
                    text = "修改成功";
                } else {
                    text = "提交成功";
                }
                saveAddress(this.addressInfo).then(u=>{
                    this.address_list();
                    this.editor_address_hide();
                    this.$message.success(text);
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            editor_address_hide() {
                this.shPersonEditor = false;
            },
            
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>

    /*------收货信息------*/
    .shPerson {
        margin: 20px;
        background: white;
    }
    .shPerson-header{
        font-size: 16px;
        color: #292929;
        height: 60px;
        line-height: 60px;
        padding:0 20px;
        border-bottom: 1px solid #CCCCCC;
    }
    .shPerson-content {
        padding: 20px;
    }
    .addSite {
        cursor: pointer;
    }
    .addSite:hover {
        color: #24B1EB;
    }
    .shPerson-content .shPerson-content-item {
        height: 48px;
        line-height: 48px;
        /*background: #CC995A;*/
        -webkit-transition: all .1s linear;
        transition: all .1s linear;
    }
    .shPerson-content .shPerson-content-item:hover {
        background: rgba(204,153,90,0.1);
    }
    .shPerson-item-left>span,.shPerson-item-right>span{
        margin-right: 20px;
        cursor: pointer;
        font-size: 14px;
        color: #4D4D4D;
    }
    .shPersonEditor {
        margin: 20px;
        background: #fff;
    }
    .shPersonEditor-content {
        padding: 20px 10px 10px 20px;
    }

    /*--------------内容--------------*/
    .listCon {
        margin:20px;
    }
    .listCon-item {
        margin-bottom: 20px;
    }
    .listCon-header {
        width: 100%;
        height: 49px;
        line-height: 49px;
        padding-left: 20px;
        background: #E2EDF8;
    }
    .listCon-table {
        padding: 20px;
        background: #fff;
    }
    .listCon-table .text {
        display: inline-block;
        vertical-align: top;
        width: 80px;
        text-align: left;
        padding-left: 10px;
    }
    .listCon-table>div.table-right {
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }
    .listCon-table-price {
        text-align: right;
        background: #F5F5F5;
        padding: 20px 0;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        border-bottom: 1px solid #EBEEF5;
    }
    .listCon-table-price p {
        font-size: 14px;
        color: #808080;
        margin-right: 30px;
    }
    .listCon-table-price p span {
        font-size: 18px;
        color: #292929;
    }
    .listCon-table-price p:last-child {
        margin-bottom: 0;
    }
    .coverImg {
        padding-right: 20px;
        cursor: pointer;
    }
    .coverImg img{
        width: 120px;
        height: 120px;
    }
    .text-name {
        text-align: left;
        font-size: 16px;
        font-weight:bold;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 17px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .text-type,.text-company,.text-mi {
        text-align: left;
        font-weight:500;
        font-size: 14px;
        color:rgba(128,128,128,1);
        margin-bottom: 10px;
    }
    .text-mi {
        color: #4D4D4D;
    }
    .text-company:last-child {
        margin-bottom: 0;
    }
    /*--------总价--------*/
    .totalPrice {
        margin: 20px;
        background: #FEF8E2;
    }
    .totalPrice .totalPrice-top {
        text-align: right;
        padding: 20px 0;
        border-bottom: 1px solid #FCECC5;
    }
    .totalPrice-top p {
        font-size: 14px;
        color: #808080;
        margin-right: 30px;
    }
    .totalPrice-top p span {
        font-size: 18px;
        color: #292929;
    }
    .totalPrice-top p:last-child {
        margin-bottom: 0;
    }
    .totalPrice-bottom {
        height: 70px;
        line-height: 70px;
        text-align: right;
    }
    .totalPrice-bottom p {
        margin-bottom: 0;
        font-size: 14px;
        color: #808080;
        margin-right: 30px;
    }
    .totalPrice-bottom p span {
        font-size: 18px;
        color: #F66F6A;
    }

</style>